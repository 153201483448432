.signup4-wrap {
//   background: url('../../../../images/backgrounds/waterfall.jpg');
}
.signup4-container {
    border-radius: 12px;
    position: relative;
    overflow: hidden;
}

.signup4-header {
    position: relative;
    padding: 35px;

    & > div {
        z-index: 2;
    }
}

.signup4-header::before{
    // background-color: rgba(3, 143, 222, 0.7);
    content: '';
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }

.signup4-small-image {
    height: 24px;
}
.signup4-form {
    padding: 35px;
}

.signup4-button {
    text-align: center;
    .mat-raised-button {
        border-radius: 18px;
    }
}