/*
 *
 *   EGRET - Angular Material Design Admin Template
 *
 *
*/

// Import Tailwind 
@tailwind base;
@tailwind components;
@tailwind utilities;

// // Third pirty style files
// // Sidebar Scroll
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// // Flag icon
// @import '~flag-icon-css/css/flag-icon.min.css';
// @import '~flag-icons/css/flag-icons.min.css';

// // Rich Text Editor
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

// // Calendar
@import 'angular-calendar/scss/angular-calendar.scss';

// // User tour
@import '~hopscotch/dist/css/hopscotch.min.css';

// // Page loader Topbar
@import '../../vendor/pace/pace-theme-min.css';

// // Highlightjs theme
@import '~highlight.js/styles/github.css';

// Main Variables File
@import "scss/variables";

@import "scss/mixins/mixins";
@import "scss/colors";

@import "scss/animations";

// Open _main.scss and keep files which are required for your current layout.
@import "scss/main/main";

// Angular material theme files
@import "scss/themes/init";

// @import "scss/utilities/utilities";
@import "scss/components/components";
@import "scss/palette";


@import "scss/page-layouts";

// Open _views.scss and remove unnecessary view related files
@import "scss/views/views";


